<template>
  <div class="my">
    <Navbar title="我的">
      <span></span>
    </Navbar>
    <img src="@/assets/images/bg_gradient.png" alt="" class="bg" />
    <div class="my-message">
      <img class="my-img" src="@/assets/images/qq.png" />
      <div class="my-detail">
        <div class="my-name">{{ my.name }}</div>
        <div class="my-id">ID:{{ my.code }}</div>
      </div>
      <div class="my-data" @click="goNext('/information')">
        <div class="wraper" :class="{ dot: !my.mobile && !loading }">
          资料<van-icon name="arrow" size="11" />
        </div>
      </div>
    </div>

    <section class="info-access">
      <h3 class="my-more">更多</h3>

      <van-cell-group :border="false">
        <van-cell
          v-for="(v, i) in myList"
          :style="{ visibility: i == myList.length - 1 ? 'hidden' : '' }"
          :key="i"
          :title="v.title"
          is-link
          :to="v.to"
          @click="clearUser(v.click)"
          :value="v.value"
          class="dark_shop_bg"
        >
            <img :src="v.img" slot="icon" />
          
        </van-cell>
      </van-cell-group>
    </section>
    <Tabbar activeIndex="2"></Tabbar>
    <van-action-sheet
      class="action-sheet"
      v-model="actionShow"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onSelect"
      @cancel="actionShow = false"
    />
  </div>
</template>

<script>
import { Cell, CellGroup, Popup, Toast } from "vant";

export default {
  data() {
    return {
      myList: [
        {
          title: "模型切换",
          img: require("@/assets/images/icon_model.png"),
          click: "model",
          value: "",
        },
        {
          title: "清除缓存",
          img: require("@/assets/images/clear.png"),
          click: "clear",
        },
        {
          title: "退出登录",
          img: require("@/assets/images/quit.png"),
          click: "quit",
        },
        {
          title: "注销账号",
          img: require("@/assets/images/logout.png"),
          click: "logout",
        },
        {
          title: "邀请好友",
          img: require("@/assets/images/invite.png"),
          to: "/invite",
        },
        //    {title:'邀请好友',img:require('@/assets/images/invite.png'),to:'/invite'},
      ],
      title: "我的",
      my: {
        name: "",
        id: "",
      },
      loading: false,
      actionShow: false,
      actions: [],
      modelName: "",
    };
  },
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
  },
  created() {
    this.getData();
    this.getCurrentModel();
    this.getModelList();
    window.clearCacheCallback = this.clearCacheCallback;
  },
  methods: {
    goNext(url) {
      this.$router.push(url);
    },
    service() {
      this.$router.push("/customerChat");
    },
    clearUser(item) {
      if (item) {
        if (item === "clear") {
          Toast({
            message: "清理完成",
            duration: 1000,
          });
          return;
        } else if (item == "quit") {
          Toast({
            message: "退出登录成功",
            duration: 1000,
          });
          localStorage.clear();
        } else if (item == "logout") {
          this.$dialog.confirm({
            title: "注销账号",
            message: "是否确认注销账号？注销后已有数据将不再保存",
            beforeClose: async (action, done) => {
              if (action === "confirm") {
                const res = await this.$api.my.logout({
                  userId: localStorage.getItem("id"),
                });
                if (res.code == 200) {
                  localStorage.clear();
                  Toast({
                    message: "注销成功",
                    duration: 1000,
                  });
                  setTimeout(() => {
                    done();
                    this.toLogin();
                  }, 1000);
                }
              } else {
                done();
              }
            },
          });
          return;
        } else if (item == "model") {
          this.actionShow = true;
          return;
        }
        setTimeout(() => {
          this.toLogin();
        }, 1000);
      }
    },
    isWeChat() {
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    // 分享给朋友
    wxShareFriends(type) {
      var personalNumber = localStorage.getItem("personalNumber"),
        link = `${
          window.location.href.split("#")[0]
        }#/register?referralCode=${personalNumber}`,
        imageUrl =
          "http://keplerinfo.oss-cn-shenzhen.aliyuncs.com/core/53BFDA69/2B3F4D68/8BE3E279/EA8BC711.png?Expires=1883455672&OSSAccessKeyId=LTAI2S35M3YS4SiT&Signature=FQ%2Fw%2FxNqJgDrYNUKcuiGm%2BSnhgk%3D",
        name = "",
        description = "";
      // 后台记录分享
      this.$server.shareApi
        .shareYPAppAddress({
          accessToken: localStorage.getItem("token"),
          customerId: localStorage.getItem("customerId"),
          shareLink: link,
          shareImage: imageUrl,
          weChatType: type,
        })
        .then((res) => {
          if (res.code === 100) {
            name = res.returnValue.shareTitle;
            description = res.returnValue.shareContent;
            var paramsObj = {
              cmd: "012", // 方法名
              link, // 分享跳转地址
              imageUrl, // 缩略图地址
              name, // 名字
              description,
              type, // 类型  0聊天界面，1朋友圈，2收藏，3联系人
            };
            // IOS
            if (this.deviceEnvir === "IOS") {
              window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);
            } else if (this.deviceEnvir === "ANDROID") {
              window.android.invokeAndroid(JSON.stringify(paramsObj));
            }
          }
        });
    },

    // 获取我的数据信息
    getData() {
      this.loading = true;
      this.$api.my.getUserDetail(localStorage.id).then((res) => {
        if (res.code === 200) {
          this.my = res.data;
          // alert(JSON.stringify(this.my))
          this.loading = false;
        }
      });
    },

    // 微信分享
    wxShare() {
      let self = this;
      switch (self.deviceEnvir) {
        case "IOS":
          let paramsObj = { cmd: "012" };
          window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);
          break;
        default:
          break;
      }
    },

    // 清除缓存
    clearStorage() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
      if (isAndroid === true) {
        window.android.invokeAndroid("cmd003");
        this.$toast("清理成功");
        this.$router.push("/index");

        return "Android";
      } else if (isIOS === true) {
        // Iphone清除缓存
        var paragramStr = ["cmd003"];

        window.webkit.messageHandlers.clearCache.postMessage(paragramStr);

        return "IOS";
      } else {
        return "PC";
      }
    },
    clearCacheCallback(num) {
      if (num == 1) {
        this.$toast("清理成功");
      } else {
        this.$toast("清理失败");
      }
    },
    toLogin() {
      console.log("退出登录");
      this.$router.replace({
        path: "/login",
        query: {
          redirect: this.$router.currentRoute.fullPath,
        },
      });
    },
    setModelValue(value) {
      this.myList.filter((item) => item.title == "模型切换")[0].value = value;
      this.modelName = value;
    },
    async onSelect(item) {
      if (item.name != this.modelName) {
        try {
          const res = await this.$api.chat.updateModel({
          customerId: localStorage.getItem("id"),
          modelId: item.modelId,
          modelName: item.modelName,
        });
        res.code == 200 && this.setModelValue(item.name);
        } catch (err) {
          err.msg.includes('没有使用权限')&&this.getModelList()
        }
      }
    },
    async getCurrentModel() {
      const res = await this.$api.chat.getCurrentModel({
        customerId: localStorage.getItem("id"),
      });
      this.model = res.data;
      this.modelName = this.model.modelName;
      this.setModelValue(this.modelName);
    },
    async getModelList() {
      const res = await this.$api.chat.getModelList(localStorage.getItem("id"));
      res.data.modelVersion.map((item) => {
        item.name = item.modelName;
        return item;
      });
      this.actions = res.data.modelVersion;
    },
  },
};
</script>

<style lang='less' scoped>
.van-cell{
  display: flex;
  align-items: center;
  img{
    width: .4rem;
    height: .4rem;
    margin-right: 6px;
  }
}
.action-sheet {
  width: 96%;
  margin-left: 2%;
  border-radius: 0;
  background: transparent;
  .van-action-sheet__content {
    margin-bottom: 0.18rem;
    border-radius: 12px;

    color: #333333;
    font-weight: 400;
    font-size: 20px;
    .van-action-sheet__item {
      padding: 18px;
      box-sizing: border-box;
      border-bottom: 1px solid #d6d6d6;
      &:nth-last-of-type(1) {
        border: none;
      }
    }
  }
  .van-action-sheet__gap {
    display: none;
  }
  .van-action-sheet__cancel {
    border-radius: 12px;
    padding: 18px;
    box-sizing: border-box;
    margin-bottom: 0.34rem;
  }
}
.my {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  background-color: #f5f7fb;

  .my-message {
    position: relative;
    width: 100%;
    display: flex;
    padding: 0 0.3rem;
    box-sizing: border-box;

    .my-img {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
    }
    .my-detail {
      display: flex;
      flex-direction: column;
      margin-left: 0.4rem;
      padding-top: 0.22rem;
      height: 1.2rem;
      .my-name {
        font-size: 0.36rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      .my-id {
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
    .my-data {
      display: flex;
      justify-content: center;
      align-content: center;

      position: absolute;
      right: 0.3rem;
      top: 50%;
      transform: translateY(-50%);

      width: 0.86rem;
      height: 0.4rem;
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 0.2rem;
      font-size: 0.22rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      > .van-icon {
        margin-top: 0.08rem;
      }
      .wraper {
        display: flex;
        align-items: center;
      }
      .dot {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 0.1rem;
          height: 0.1rem;
          border-radius: 50%;
          background: red;
          right: -0.1rem;
          top: 0;
        }
      }
    }
  }

  .van-cell-group {
    background: transparent;
  }
  .info-access {
    // margin: 2.7rem .3rem 0;
    margin: 0.7rem 0.3rem 0;
    // background-color:#F5F7FB ;

    .my-more {
      position: relative;
      font-size: 0.32rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      // margin: 0 0 .04rem .32rem;

      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 0.62rem;
        height: 0.08rem;
        background: linear-gradient(-90deg, #3764ff, rgba(255, 255, 255, 0));
      }
    }
    .van-cell {
      letter-spacing: 1px;

      background: transparent;
      padding: 0.3rem 0;
      position: relative;
      &::after {
        position: absolute;
        box-sizing: border-box;
        content: " ";
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 2px solid #ececec;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
      }
      & > span {
        font-size: 0.44rem /* 22/50 */;
        margin-right: 0.32rem /* 16/50 */;
      }

      .van-cell__title {
        font-size: 0.28rem /* 14/50 */;
        color: #333;
      }

      .van-cell__value {
        font-size: 0.24rem /* 12/50 */;
      }
    }
  }
}
</style>
